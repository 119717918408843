body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Font for Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif !important;
  font-weight: 900 !important;
}

/* Fallback Font */
p {
  font-family: sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #carouselExampleCaptions {
  margin-top: 30px !important;
} */

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust opacity as needed */
  pointer-events: none; /* Allow interaction with content beneath */
  z-index: 2;
}



.carousel-caption {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  height: 100% !important;

  /* Ensure the caption takes up the full height of the carousel item */
}

/* Default font size for larger screens */
.carousel-caption p {
  font-size: 1.2em;
}

.carousel-caption div h3 {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: "2px 2px 4px rgba(0,0,0,0.8)";
  font-weight: "bold",
}

/* HeroCarousel.css */



/* Media queries and other styles here */


/* Media query for screens smaller than 768px wide */
@media (max-width: 1075px) {
  .carousel-caption p {
    font-size: 1em;
    /* Decrease font size for smaller screens */
  }

  .carousel-caption div h3 {
    font-size: 1.5em;
  }

  #carouselExampleCaptions {
    margin-top: 90px !important;
  }
}

/* Media query for screens smaller than 768px wide */
@media (max-width: 768px) {
  .carousel-caption p {
    font-size: 0.8em;
    /* Decrease font size for smaller screens */
  }

  .carousel-caption div h3 {
    font-size: 1em;
  }

  #carouselExampleCaptions {
    margin-top: 90px !important;
  }
}

@media (max-width: 457px) {
  .carousel-caption p {
    font-size: 0.7em;
    /* Decrease font size for smaller screens */
  }

  .carousel-caption div h3 {
    font-size: 0.8em;
  }

  #carouselExampleCaptions {
    margin-top: 90px !important;
  }

}




.carousel-inner {
  text-align: center !important;
  /* Center-align the text within the caption */
}

/* Style for the email button container */
#email-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  background-color: #DB504A;
  /* Change the background color */
  border-radius: 50px;
  /* Make the container rounded */
  padding: 12px;
  cursor: pointer;
  display: flex;
  /* Use flexbox to arrange items in a row */
  align-items: center;
  /* Center items vertically */
  gap: 8px;
  /* Adjust the spacing between icon and text */
}

/* Style for the email icon */
#email-icon {
  color: white;
  /* Change the icon color */
  font-size: large;
}

/* Style for the email text */
#email-text {
  color: white;
  /* Change the text color */
  font-weight: bolder;
}

/* styles.css */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}