.nav-item:hover {
    color: #DB504A;
    border-bottom: 3px solid #DB504A;
  }


.nav-item {
    color: #165B76;
}

.phone-container .phone-number:hover {
    color: #DB504A;
}

.phone-container .phone-icon:hover {
    color: #DB504A;
}

.active-link {
    color: #DB504A;
    border-bottom: 3px solid #DB504A;
  }